import axios from '@/plugins/axios'

const getLogin = (formData) => axios.post('auth/login', formData)
const sendSms = (email) => axios.get(`auth/login/${email}/sms`)
const getLoginConfirm = ({ email, formData }) => axios.post(`auth/login/${email}/sms`, formData)
const checkUserAccess = () => axios.post('auth/check')
const getLogout = () => axios.post('auth/logout')

export {
    getLogin,
    sendSms,
    checkUserAccess,
    getLogout,
    getLoginConfirm,
}
